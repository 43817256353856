.prev,
.next {
    cursor: pointer;
    position: absolute;

    width: auto;
    margin-top: 25px;
    color: #fff !important;
    font-weight: bold;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

@media (min-width: 320px) {

    .prev,
    .next {
        margin-top: 155px;
    }

    .next-right {
        margin-left: 250px
    }

    .MuiButton-containedPrimary {
        font-size: 12px !important;
    }

    .mobile {
        border-bottom: 1px solid #929292 !important;
    }

   
    .contentManual {
        padding-left: 25px;
    }

}

@media (min-width: 360px) {
    .next-right {
        margin-left: 290px
    }


}

@media (min-width: 375px) {
    .next-right {
        margin-left: 310px
    }

    .MuiButton-containedPrimary {
        font-size: 14px !important;
    }
}

@media (max-width: 767px) {
    .next-right {
        right: 0;
        margin-right: 24px;
    }
}

@media (min-width: 768px) {

    .prev,
    .next {
        margin-top: 45px;
    }

    .next-right {
        margin-left: 475px
    }

    .totLaporan {
        padding-top: 10px;
        font-size: 16px;
    }

    .itemName {
        min-width: 200px;
    }

    .contentManual {
        padding: 5px 30px;
    }
}

@media (min-width: 992px) {

    .prev,
    .next {
        /* position: fixed; */
        margin-top: 45px;
    }

    .next-right {
        margin-left: 578px
    }

    .totLaporan {
        font-size: 19px;
    }
}

@media (min-width: 1200px) {

    .prev,
    .next {
        /* position: fixed; */
        margin-top: 45px;
    }

    .next-right {
        margin-left: 695px
    }
}

.activeList {
    background-color: #bfbcbc;
}



.grafikMax>canvas {
    max-height: 400px !important;
}

.marginGrafik {
    margin-top: 20px;
}

.tabelCenter>th {
    text-align: center;
}

.deleteDialog {
    margin-top: 15px;
    cursor: pointer;
}

.bgRowCancel>td {
    color: #fff !important;
}
.bodySessionDetail {
    display: block;
    overflow: auto;
    width: 100%;
}
.login{
    background: #fbfbfb;
    border-radius: 8px;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65);
    padding: 13px;
    margin: 0px -26px;
}
.MuiListItemText-root > .MuiListItemText-primary{
    color: #000;
}
.iconLogout > .MuiListItemIcon-root > .MuiSvgIcon-root, .iconLogout > .MuiListItemText-root > .MuiListItemText-primary {
    color: #ff0000  !important;
}
.table-responsive-manual {
    width: 90%;
}